exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accuracy-js": () => import("./../../../src/pages/accuracy.js" /* webpackChunkName: "component---src-pages-accuracy-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-edgeu-glossary-js": () => import("./../../../src/pages/edgeu-glossary.js" /* webpackChunkName: "component---src-pages-edgeu-glossary-js" */),
  "component---src-pages-edgeu-strategies-js": () => import("./../../../src/pages/edgeu-strategies.js" /* webpackChunkName: "component---src-pages-edgeu-strategies-js" */),
  "component---src-pages-edgeu-training-js": () => import("./../../../src/pages/edgeu-training.js" /* webpackChunkName: "component---src-pages-edgeu-training-js" */),
  "component---src-pages-edgeuniversity-js": () => import("./../../../src/pages/edgeuniversity.js" /* webpackChunkName: "component---src-pages-edgeuniversity-js" */),
  "component---src-pages-emailverified-js": () => import("./../../../src/pages/emailverified.js" /* webpackChunkName: "component---src-pages-emailverified-js" */),
  "component---src-pages-emailverifiederror-js": () => import("./../../../src/pages/emailverifiederror.js" /* webpackChunkName: "component---src-pages-emailverifiederror-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loading-js": () => import("./../../../src/pages/loading.js" /* webpackChunkName: "component---src-pages-loading-js" */),
  "component---src-pages-mobileapp-js": () => import("./../../../src/pages/mobileapp.js" /* webpackChunkName: "component---src-pages-mobileapp-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-offers-js": () => import("./../../../src/pages/offers.js" /* webpackChunkName: "component---src-pages-offers-js" */),
  "component---src-pages-plans-js": () => import("./../../../src/pages/plans.js" /* webpackChunkName: "component---src-pages-plans-js" */),
  "component---src-pages-platform-js": () => import("./../../../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-quantfundalgos-js": () => import("./../../../src/pages/quantfundalgos.js" /* webpackChunkName: "component---src-pages-quantfundalgos-js" */),
  "component---src-pages-roadmap-js": () => import("./../../../src/pages/roadmap.js" /* webpackChunkName: "component---src-pages-roadmap-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-signupdetails-js": () => import("./../../../src/pages/signupdetails.js" /* webpackChunkName: "component---src-pages-signupdetails-js" */),
  "component---src-pages-signupproblem-js": () => import("./../../../src/pages/signupproblem.js" /* webpackChunkName: "component---src-pages-signupproblem-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-verification-js": () => import("./../../../src/pages/verification.js" /* webpackChunkName: "component---src-pages-verification-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */)
}

